import React, { useRef } from "react"
import Header from "../header/Header"
import Footer from "../footer/Footer"
import Button from "../ui/button/Button"
import "./Contact.css"
import { emails } from "../../../site_data/contactdata.json"
const Contact = () => {
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const messageRef = useRef(null)
  const hearAboutUsRef = useRef(null)
  let mailingList = ""
  emails.forEach(mail => {
    mailingList += mail + ","
  })
  const submitForm = () => {
    const message = messageRef.current.value
    const hearAboutUsRefMessage =
      hearAboutUsRef.current.value.trim() == ""
        ? "N/A"
        : hearAboutUsRef.current.value
    const name =
      firstNameRef.current.value.trim() + " " + lastNameRef.current.value.trim()
    if (name === " " || message === "") {
      alert("Please provide all fields")
      return
    }
    const mailStr =
      "mailto:" +
      mailingList +
      "?subject=Message from C3 FUND by " +
      name +
      "&body=" +
      message +
      "%0D%0A%0D%0A I heard about you from: " +
      hearAboutUsRefMessage
    window.open(mailStr)
    firstNameRef.current.value = ""
    lastNameRef.current.value = ""
    messageRef.current.value = ""
    hearAboutUsRef.current.value = ""
  }

  return (
    <React.Fragment>
      <div className="contact">
        <div className="row">
          <div className="col-xl-6 col-sm-12 contact-first">
            <p className="contact-first-title">
              Ai + Machine Learning-
              <br /> is it the right investment
              <br /> tool for you?
            </p>
            <p className="contact-first-body">
              Invest using automated algorithmic trading for capital
              preservation, risk management and long-term growth.
            </p>
          </div>
          <div className="col-xl-6 col-sm-12">
            <table className="contact-table">
              <tr>
                <td>
                  <label>FIRST NAME</label> <br />
                  <input
                    ref={firstNameRef}
                    type="text"
                    placeholder="Your first name"
                    required
                  />
                </td>
                <td>
                  <label>LAST NAME</label> <br />
                  <input
                    ref={lastNameRef}
                    type="text"
                    placeholder="Your last name"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <label>How did you hear about us? *</label> <br />
                  <input
                    ref={hearAboutUsRef}
                    type="text"
                    placeholder="How did you hear about us? (Optional)"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <label>MESSAGE</label> <br />
                  <textarea
                    ref={messageRef}
                    rows={7}
                    required
                    placeholder="Write your message here..."
                  ></textarea>
                </td>
              </tr>
            </table>
            <div className="contact-btn">
              <button onClick={submitForm} className="c3_solid_btn">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Contact
