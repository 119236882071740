import React from "react"
import Header from "../components/header/Header"
import Contact from "../components/contact/Contact"
import FooterNew from "../components/footer/FooterNew"
import { BrowserRouter } from "react-router-dom"

const about = () => {
  return (
    <div className="body-container_1">
      <Header />
      <Contact />
      <FooterNew />
    </div>
  )
}

export default about
